
<template>
    <div v-if="edited">
        <div class="row mb-4" v-if="edited.id && $privileges.loggedUserHasRight(PRIVILEGES.PRIVILEGE_SYSTEMUSERS, PRIVILEGES.PRIVILEGE_KIND_DELETE)">
            <div class="col col-12 text-end">
                <b-btn variant="danger" @click="onDelete">usuń</b-btn>
            </div>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
            <div class="row">
                <div class="col col-md-6 col-sm-12">
                    <InputField type="text"
                                label="Imię Nazwisko"
                                name="Imię Nazwisko"
                                v-model="edited.name"
                                label-class="required"
                                rules="required"
                                :readonly="readonly"
                                :disabled="readonly"/>
                </div>
                <div class="col col-md-6 col-sm-12">
                    <InputField type="text"
                                label="Email"
                                name="Email"
                                v-model="edited.email"
                                label-class="required"
                                rules="required|email"
                                :readonly="readonly || selected.id > 0"
                                :disabled="readonly || selected.id > 0"/>
                </div>
            </div>

            <div class="row" v-if="$privileges.loggedUserHasRight(PRIVILEGES.PRIVILEGE_SYSTEMUSERS, selected.id > 0 ? PRIVILEGES.PRIVILEGE_KIND_EDIT : PRIVILEGES.PRIVILEGE_KIND_ADD)">
                <div class="col text-end">
                    <b-btn variant="success" @click="handleSubmit(onSave)">zapisz</b-btn>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import * as TYPES from '@/store/types';
import * as PRIVILEGES from '@/config/Privileges'
import InputField from '@/components/Forms/InputField'
import {mapGetters} from 'vuex'
import swal from 'sweetalert2'
import axios from 'axios';

export default {
    name: "DetailsPage_data",
    components: {
        InputField
    },
    props: {
        selected: {
            required: true
        }
    },
    watch: {
        selected(newVal, oldVal) {
            this.edited = JSON.parse(JSON.stringify(newVal))
        }
    },
    data() {
        return {
            edited: null,
            fileIcon: null,
            PRIVILEGES
        }
    },
    computed: {
        ...mapGetters({
            authUser: [TYPES.AUTH_USER_GET_DATA]
        }),
        readonly() {
            return !this.$privileges.loggedUserHasRight(PRIVILEGES.PRIVILEGE_SYSTEMUSERS, this.edited && this.edited.id > 0 ? PRIVILEGES.PRIVILEGE_KIND_EDIT : PRIVILEGES.PRIVILEGE_KIND_ADD)
        }
    },
    methods: {
        isFormChanged() {
            return this.$validator.fields.items.some(field => {return field.flags.changed} );
        },
        onSave() {
            this.$store.dispatch(TYPES.SYSTEMUSERS_A_SAVE_SELECTED, this.edited)
                .then( (saved) => {
                    if (this.authUser.id === saved.id) {
                        this.$store.dispatch(TYPES.AUTH_USER_LOAD)
                    }
                });
        },
        onDelete() {
            new swal({
                title: 'Usunąć użytkownika?',
                text: "Nie będzie możliwe przywrócenie tego użytkownika!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Tak, usuń!',
                cancelButtonText: 'Nie'
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch(TYPES.SYSTEMUSERS_A_DELETE_SELECTED, this.edited)
                        .then(() => {
                            this.$router.push('/systemusers');
                        });
                }
            });
        }
    },
    created() {

    },
    mounted() {
        console.log('details tab')
        this.edited = JSON.parse(JSON.stringify(this.selected));
    },
}
</script>

<style scoped>

</style>
