
<template>
    <div v-if="edited">
        <div class="row mb-4">
            <div class="col col-4"></div>
            <div class="col col-2" v-for="name in ['Pokaż', 'Edytuj', 'Dodaj', 'Usuń']" :key="name">
                <h5>{{name}}</h5>
            </div>
        </div>
        <div class="row" v-for="privilege in Object.keys(privileges)" :key="privilege">
            <div class="col col-4">
                <h4>{{privileges[privilege].name}}</h4>
            </div>
            <div class="col col-2 text-center" v-for="kind in [{k: 'V', v: 'info'}, {k:'E', v: 'warning'}, {k:'A', v: 'success'}, {k:'D',v:'danger'}]" :key="kind.k">
                <InputField type="switch"
                            v-if="privileges[privilege][kind.k]"
                            name="Imię Nazwisko"
                            :value="$privileges.userHasRight(selected, privilege, kind.k, true)"
                            @input="(val) => onChangePrivilege(privilege, kind.k, val)"
                            label-class="required"
                            rules="required"
                            :readonly="readonly"
                            :variant="kind.v"
                />
            </div>

        </div>

        <div class="row" v-if="$privileges.loggedUserHasRight(PRIVILEGES.PRIVILEGE_SYSTEMUSER_PRIVILEGES, PRIVILEGES.PRIVILEGE_KIND_EDIT)">
            <div class="col text-end">
                <b-btn variant="success" @click="onSave">zapisz</b-btn>
            </div>
        </div>

    </div>
</template>

<script>
import * as TYPES from '@/store/types';
import * as PRIVILEGES from '@/config/Privileges'
import InputField from '@/components/Forms/InputField'
import {mapGetters} from 'vuex'
import swal from 'sweetalert2'
import axios from 'axios';

export default {
    name: "DetailsPage_data",
    components: {
        InputField
    },
    props: {
        selected: {
            required: true
        }
    },
    watch: {
        selected(newVal, oldVal) {
            this.edited = JSON.parse(JSON.stringify(newVal))
        }
    },
    data() {
        return {
            edited: null,
            privileges: [],
            PRIVILEGES
        }
    },
    computed: {
        ...mapGetters({
            authUser: [TYPES.AUTH_USER_GET_DATA]
        }),
        readonly() {
            return !this.$privileges.loggedUserHasRight(PRIVILEGES.PRIVILEGE_SYSTEMUSER_PRIVILEGES, PRIVILEGES.PRIVILEGE_KIND_EDIT)
        }
    },
    methods: {
        isFormChanged() {
            return this.$validator.fields.items.some(field => {return field.flags.changed} );
        },
        onSave() {

            this.$store.dispatch(TYPES.SYSTEMUSERS_A_SAVE_SELECTED, this.edited)
                .then( (saved) => {
                    if (this.authUser.id === saved.id) {
                        this.$store.dispatch(TYPES.AUTH_USER_LOAD)
                    }
                });


        },
        loadPrivileges() {
            return axios.get('privileges')
                .then((response) => {
                    this.privileges = response.data;
                })
        },
        onChangePrivilege(privilege, kind, value) {
            if (!this.edited.user_privileges) {
                this.edited.user_privileges = {}
            }
            if (!this.edited.user_privileges[privilege]) {
                this.edited.user_privileges[privilege] = {};
            }
            if (!this.edited.user_privileges[privilege][kind]) {
                this.edited.user_privileges[privilege][kind] = 0;
            }
            this.edited.user_privileges[privilege][kind] = value;

            console.log('privileges', this.edited.user_privileges)
        }
    },
    created() {

    },
    mounted() {
        this.loadPrivileges();
        this.edited = JSON.parse(JSON.stringify(this.selected));
    },
}
</script>

<style scoped>

</style>
