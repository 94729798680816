
<template>
    <div v-if="edited && edited.id > 0">
        <div class="row">
            <div class="col-12 text-center">
                <div class="mb-3" style="height: 400px;">
                    <croppa v-if="!imageLoading" v-model="myCroppa" :prevent-white-space="true" :zoom-speed="10"
                            :initial-image="img"
                            :width="300"
                            :height="300"
                            placeholder="wybierz zdjęcie"
                    ></croppa><br/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-end">
                <button class="btn btn-success" @click="uploadCroppedImage">zapisz</button>
            </div>
        </div>
    </div>
</template>

<script>
import * as TYPES from '@/store/types';
import * as PRIVILEGES from '@/config/Privileges'
import InputField from '@/components/Forms/InputField'
import {mapGetters} from 'vuex'
import swal from 'sweetalert2'

import axios from 'axios';

export default {
    name: "DetailsPage_data",
    components: {
        InputField,
    },
    props: {
        selected: {
            required: true
        }
    },
    watch: {
        selected(newVal, oldVal) {
            this.edited = JSON.parse(JSON.stringify(newVal))
        }
    },
    data() {
        return {
            imageLoading: false,
            edited: null,
            myCroppa: {},
            img: null,
            PRIVILEGES
        }
    },
    computed: {
        ...mapGetters({
            authUser: [TYPES.AUTH_USER_GET_DATA]
        }),
        readonly() {
            !this.$privileges.loggedUserHasRight(PRIVILEGES.PRIVILEGE_SYSTEMUSERS, this.edited && this.edited.id > 0 ? PRIVILEGES.PRIVILEGE_KIND_EDIT : PRIVILEGES.PRIVILEGE_KIND_ADD)
        },
        path() {
            return this.edited.picture_download_url;
            return 'http://localhost:8000/api/panel/systemusers/'+ this.edited.id + '/download_avatar';
        }
    },
    methods: {
        isFormChanged() {
            return this.$validator.fields.items.some(field => {return field.flags.changed} );
        },
        onSave() {
            this.$store.dispatch(TYPES.SYSTEMUSERS_A_SAVE_SELECTED, this.edited)
                .then( (saved) => {
                    if (this.authUser.id === saved.id) {
                        this.$store.dispatch(TYPES.AUTH_USER_LOAD)
                    }
                });
        },
        uploadCroppedImage() {
            if (!this.myCroppa.hasImage()) {
                alert('no image to upload')
                return
            }
            this.myCroppa.generateBlob((blob) => {
                this.$store.dispatch(TYPES.SYSTEMUSERS_A_UPLOAD_AVATAR, {id: this.selected.id, imageBlob: blob})
            }, 'image/jpeg', 0.8) // 80% compressed jpeg file

        },
        loadInitialAvatar() {
            if (this.selected.picture_download_url) {
                this.imageLoading = true;
                return axios.get(this.selected.picture_download_url, {
                    responseType: 'arraybuffer'
                }).then((response) => {
                    var mimeType = response.headers['content-type'].toLowerCase();
                    var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
                    let picture_src = 'data:' + mimeType + ';base64,' + imgBase64;
                    this.img = new Image()
                    this.img.src = picture_src;
                    this.$forceUpdate();
                }).catch((function () {

                })).finally(() => {
                    this.imageLoading = false;
                })
            }
        }
    },
    created() {
        this.loadInitialAvatar();
    },
    mounted() {
        console.log('details tab')

        this.edited = JSON.parse(JSON.stringify(this.selected));
    },
}
</script>

<style scoped>

</style>
