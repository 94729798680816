<template>
    <common-details-page title="Użytkownik"
                  :is-busy="isBusy"
                  :tabs="[
                      {title: 'Dane', active: true, slotName: 'dataTab'},
                      {title: 'Awatar', active: true, slotName: 'avatarTab'},
                      {title: 'Uprawnienia', active: true, slotName: 'privilegesTab'},
                  ]"
    >
        <template v-slot:header>
            <page-header></page-header>
        </template>
        <template v-slot:dataTab>
            <data-tab :selected="selected" v-if="selected"></data-tab>
        </template>
        <template v-slot:avatarTab>
            <avatar-tab :selected="selected"></avatar-tab>
        </template>
        <template v-slot:privilegesTab>
            <privileges-tab :selected="selected" v-if="selected && selected.id > 0"></privileges-tab>
        </template>
    </common-details-page>
</template>

<script>
import CommonDetailsPage from "@/pages/Common/DetailsPage";
import {mapGetters} from "vuex";
import * as TYPES from '@/store/types';
import DataTab from './DetailsPage_data'
import PrivilegesTab from './DetailsPage_privileges'
import AvatarTab from './DetailsPage_avatar'
import PageHeader from "./PageHeader";
export default {
    name: "SystemUsersDetailsPage",
    components: {
        PageHeader,
        CommonDetailsPage,
        DataTab,
        PrivilegesTab,
        AvatarTab
    },
    data() {
        return {

        };
    },
    computed: {
        ...mapGetters({
            selected: [TYPES.SYSTEMUSERS_G_GET_SELECTED],
            isBusy: [TYPES.SYSTEMUSERS_G_GET_IS_BUSY]
        })
    },
    created() {
        this.$store.dispatch(TYPES.SYSTEMUSERS_A_LOAD_SELECTED, this.$route.params.id)
    }
}
</script>

<style scoped>

</style>